export const EQUIPMENT = [
	{
		'name': 'Axe, battle',
		'qty': null,
		'cost': '6',
		'denomination': 'gp',
		'pounds': '6',
		'oneHandDamage': null,
		'twoHandDamage': '1d8',
		'armorClass': null,
		'type': 'Weapon',
		'notes': ''
	}, {
		'name': 'Axe, hand',
		'qty': null,
		'cost': '1',
		'denomination': 'gp',
		'pounds': '3',
		'oneHandDamage': '1d6',
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Weapon',
		'notes': ''
	}, {
		'name': 'Club',
		'qty': null,
		'cost': '3',
		'denomination': 'gp',
		'pounds': '3',
		'oneHandDamage': '1d4',
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Weapon',
		'notes': ''
	}, {
		'name': 'Crossbow, heavy',
		'qty': null,
		'cost': '25',
		'denomination': 'gp',
		'pounds': '8',
		'oneHandDamage': '',
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Weapon',
		'notes': ''
	}, {
		'name': 'Heavy quarrels and case',
		'qty': '10',
		'cost': '3',
		'denomination': 'gp',
		'pounds': '1',
		'oneHandDamage': '1d8',
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Weapon',
		'notes': ''
	}, {
		'name': 'Crossbow, light',
		'qty': null,
		'cost': '16',
		'denomination': 'gp',
		'pounds': '4',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Weapon',
		'notes': ''
	}, {
		'name': 'Light quarrels and case',
		'qty': '10',
		'cost': '3',
		'denomination': 'ep',
		'pounds': '1',
		'oneHandDamage': '1d6',
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Weapon',
		'notes': ''
	}, {
		'name': 'Dagger',
		'qty': null,
		'cost': '3',
		'denomination': 'gp',
		'pounds': '1',
		'oneHandDamage': '1d4',
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Weapon',
		'notes': ''
	}, {
		'name': 'Dagger, silver',
		'qty': null,
		'cost': '30',
		'denomination': 'gp',
		'pounds': '1',
		'oneHandDamage': '1d4',
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Weapon',
		'notes': ''
	}, {
		'name': 'Dart',
		'qty': null,
		'cost': '5',
		'denomination': 'sp',
		'pounds': '.5',
		'oneHandDamage': '1d4',
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Weapon',
		'notes': ''
	}, {
		'name': 'Flail',
		'qty': null,
		'cost': '3',
		'denomination': 'gp',
		'pounds': '5',
		'oneHandDamage': '1d6',
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Weapon',
		'notes': ''
	}, {
		'name': 'Flail, heavy',
		'qty': null,
		'cost': '8',
		'denomination': 'gp',
		'pounds': '10',
		'oneHandDamage': null,
		'twoHandDamage': '1d8',
		'armorClass': null,
		'type': 'Weapon',
		'notes': ''
	}, {
		'name': 'Hammer, light',
		'qty': null,
		'cost': '1',
		'denomination': 'gp',
		'pounds': '2',
		'oneHandDamage': '1d4',
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Weapon',
		'notes': ''
	}, {
		'name': 'Hammer, war',
		'qty': null,
		'cost': '7',
		'denomination': 'gp',
		'pounds': '5',
		'oneHandDamage': null,
		'twoHandDamage': '1d6',
		'armorClass': null,
		'type': 'Weapon',
		'notes': ''
	}, {
		'name': 'Javelin',
		'qty': null,
		'cost': '1',
		'denomination': 'gp',
		'pounds': '2',
		'oneHandDamage': '1d6',
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Weapon',
		'notes': ''
	}, {
		'name': 'Lance',
		'qty': null,
		'cost': '7',
		'denomination': 'gp',
		'pounds': '10',
		'oneHandDamage': '1d6',
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Weapon',
		'notes': ''
	}, {
		'name': 'Longbow',
		'qty': null,
		'cost': '40',
		'denomination': 'gp',
		'pounds': '3',
		'oneHandDamage': '',
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Weapon',
		'notes': ''
	}, {
		'name': 'Quiver and arrows, Longbow',
		'qty': '20',
		'cost': '5',
		'denomination': 'gp',
		'pounds': '3',
		'oneHandDamage': '1d8',
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Weapon',
		'notes': ''
	}, {
		'name': 'Mace',
		'qty': null,
		'cost': '5',
		'denomination': 'gp',
		'pounds': '3',
		'oneHandDamage': '1d6',
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Weapon',
		'notes': ''
	}, {
		'name': 'Morningstar',
		'qty': null,
		'cost': '5',
		'denomination': 'gp',
		'pounds': '6',
		'oneHandDamage': null,
		'twoHandDamage': '1d6',
		'armorClass': null,
		'type': 'Weapon',
		'notes': ''
	}, {
		'name': 'Pick, heavy',
		'qty': null,
		'cost': '8',
		'denomination': 'gp',
		'pounds': '6',
		'oneHandDamage': null,
		'twoHandDamage': '1d8',
		'armorClass': null,
		'type': 'Weapon',
		'notes': ''
	}, {
		'name': 'Pick, light',
		'qty': null,
		'cost': '5',
		'denomination': 'gp',
		'pounds': '3',
		'oneHandDamage': '1d6',
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Weapon',
		'notes': ''
	}, {
		'name': 'Pole Arm',
		'qty': null,
		'cost': '7',
		'denomination': 'gp',
		'pounds': '15',
		'oneHandDamage': null,
		'twoHandDamage': '1d10',
		'armorClass': null,
		'type': 'Weapon',
		'notes': ''
	}, {
		'name': 'Quarterstaff',
		'qty': null,
		'cost': '2',
		'denomination': 'gp',
		'pounds': '4',
		'oneHandDamage': null,
		'twoHandDamage': '1d6',
		'armorClass': null,
		'type': 'Weapon',
		'notes': ''
	}, {
		'name': 'Scimitar',
		'qty': null,
		'cost': '15',
		'denomination': 'gp',
		'pounds': '4',
		'oneHandDamage': '1d8',
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Weapon',
		'notes': ''
	}, {
		'name': 'Shortbow',
		'qty': null,
		'cost': '25',
		'denomination': 'gp',
		'pounds': '2',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Weapon',
		'notes': ''
	}, {
		'name': 'Quiver and arrows, Shortbow',
		'qty': '20',
		'cost': '5',
		'denomination': 'gp',
		'pounds': '3',
		'oneHandDamage': '1d6',
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Weapon',
		'notes': ''
	}, {
		'name': 'Sling',
		'qty': null,
		'cost': '2',
		'denomination': 'gp',
		'pounds': '0',
		'oneHandDamage': '',
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Weapon',
		'notes': ''
	}, {
		'name': 'Bullets, sling',
		'qty': '10',
		'cost': '0',
		'denomination': 'gp',
		'pounds': '5',
		'oneHandDamage': '1d4',
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Weapon',
		'notes': ''
	}, {
		'name': 'Spear',
		'qty': null,
		'cost': '3',
		'denomination': 'gp',
		'pounds': '6',
		'oneHandDamage': '1d6',
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Weapon',
		'notes': ''
	}, {
		'name': 'Sword, long',
		'qty': null,
		'cost': '10',
		'denomination': 'gp',
		'pounds': '4',
		'oneHandDamage': '1d8',
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Weapon',
		'notes': ''
	}, {
		'name': 'Sword, bastard',
		'qty': null,
		'cost': '20',
		'denomination': 'gp',
		'pounds': '6',
		'oneHandDamage': '1d8',
		'twoHandDamage': '2d4',
		'armorClass': null,
		'type': 'Weapon',
		'notes': ''
	}, {
		'name': 'Sword, short',
		'qty': null,
		'cost': '7',
		'denomination': 'gp',
		'pounds': '2',
		'oneHandDamage': '1d6',
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Weapon',
		'notes': ''
	}, {
		'name': 'Sword, two-handed',
		'qty': null,
		'cost': '15',
		'denomination': 'gp',
		'pounds': '15',
		'oneHandDamage': null,
		'twoHandDamage': '1d10',
		'armorClass': null,
		'type': 'Weapon',
		'notes': ''
	}, {
		'name': 'Trident',
		'qty': null,
		'cost': '4',
		'denomination': 'gp',
		'pounds': '4',
		'oneHandDamage': null,
		'twoHandDamage': '1d6',
		'armorClass': null,
		'type': 'Weapon',
		'notes': ''
	}, {
		'name': 'Banded mail',
		'qty': null,
		'cost': '85',
		'denomination': 'gp',
		'pounds': '35',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': '4',
		'type': 'Armor',
		'notes': ''
	}, {
		'name': 'Chain mail',
		'qty': null,
		'cost': '70',
		'denomination': 'gp',
		'pounds': '30',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': '5',
		'type': 'Armor',
		'notes': ''
	}, {
		'name': 'Helmet',
		'qty': null,
		'cost': '10',
		'denomination': 'gp',
		'pounds': '5',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'armorClassMod': -1,
		'type': 'Armor',
		'notes': 'Lowers Armor Class by 1'
	}, {
		'name': 'Horse barding',
		'qty': null,
		'cost': '150',
		'denomination': 'gp',
		'pounds': '60',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': '5',
		'type': 'Armor',
		'notes': ''
	}, {
		'name': 'Leather',
		'qty': null,
		'cost': '6',
		'denomination': 'gp',
		'pounds': '15',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': '8',
		'type': 'Armor',
		'notes': ''
	}, {
		'name': 'Padded',
		'qty': null,
		'cost': '4',
		'denomination': 'gp',
		'pounds': '10',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': '8',
		'type': 'Armor',
		'notes': ''
	}, {
		'name': 'Plate mail',
		'qty': null,
		'cost': '450',
		'denomination': 'gp',
		'pounds': '50',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': '3',
		'type': 'Armor',
		'notes': ''
	}, {
		'name': 'Scale mail',
		'qty': null,
		'cost': '50',
		'denomination': 'gp',
		'pounds': '40',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': '6',
		'type': 'Armor',
		'notes': ''
	}, {
		'name': 'Shield',
		'qty': null,
		'cost': '10',
		'denomination': 'gp',
		'pounds': '10',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'armorClassMod': -1,
		'type': 'Armor',
		'notes': 'Lowers armor class by 1'
	}, {
		'name': 'Splint mail',
		'qty': null,
		'cost': '75',
		'denomination': 'gp',
		'pounds': '45',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': '4',
		'type': 'Armor',
		'notes': ''
	}, {
		'name': 'Studded leather',
		'qty': null,
		'cost': '30',
		'denomination': 'gp',
		'pounds': '20',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': '7',
		'type': 'Armor',
		'notes': ''
	}, {
		'name': 'Backpack',
		'qty': null,
		'cost': '2',
		'denomination': 'gp',
		'pounds': '2',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': 'A backpack has two straps and can be worn on the back, keeping the hands free. It holds up to 40 pounds.'
	}, {
		'name': 'Barrel, wooden',
		'qty': null,
		'cost': '1',
		'denomination': 'gp',
		'pounds': '40',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Bedroll',
		'qty': null,
		'cost': '1',
		'denomination': 'sp',
		'pounds': '5',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Blanket, winter',
		'qty': null,
		'cost': '5',
		'denomination': 'sp',
		'pounds': '3',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Block and tackle',
		'qty': null,
		'cost': '5',
		'denomination': 'gp',
		'pounds': '5',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Bottle, wine, glass',
		'qty': null,
		'cost': '2',
		'denomination': 'gp',
		'pounds': '·',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Box, large iron',
		'qty': null,
		'cost': '30',
		'denomination': 'gp',
		'pounds': '60',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Box, small iron',
		'qty': null,
		'cost': '10',
		'denomination': 'gp',
		'pounds': '8',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Candles',
		'qty': '10',
		'cost': '10',
		'denomination': 'cp',
		'pounds': '·',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': 'A candle dimly illuminates a 5-foot radius and burns for 1 hour.'
	}, {
		'name': 'Case, map or scroll',
		'qty': null,
		'cost': '1',
		'denomination': 'gp',
		'pounds': '0.5',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Cask, wooden',
		'qty': null,
		'cost': '1',
		'denomination': 'ep',
		'pounds': '15',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Chest, large wooden',
		'qty': null,
		'cost': '1',
		'denomination': 'gp',
		'pounds': '40',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Chest, small wooden',
		'qty': null,
		'cost': '1',
		'denomination': 'ep',
		'pounds': '5',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Chain',
		'qty': '10 ft',
		'cost': '30',
		'denomination': 'gp',
		'pounds': '2',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Crowbar',
		'qty': null,
		'cost': '2',
		'denomination': 'gp',
		'pounds': '5',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': 'A crowbar is 2 or 3 feet long and made of solid iron. This object can be used for forcing doors and other objects open.'
	}, {
		'name': 'Flask (empty)',
		'qty': null,
		'cost': '3',
		'denomination': 'cp',
		'pounds': '1.5',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Flint and steel',
		'qty': null,
		'cost': '2',
		'denomination': 'gp',
		'pounds': '·',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Garlic',
		'qty': '2 cloves',
		'cost': '5',
		'denomination': 'gp',
		'pounds': '·',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': 'This herb is effective at repelling vampires. It may be used to treat bacterial infection, viral infection, fungal infection, and intestinal parasites'
	}, {
		'name': 'Grappling hook',
		'qty': null,
		'cost': '1',
		'denomination': 'gp',
		'pounds': '4',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Hammer',
		'qty': null,
		'cost': '5',
		'denomination': 'sp',
		'pounds': '2',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': 'If used to fight, this small hammer deals 1d4 damage. It can be used for construction, or as a mallet with iron or wooden spikes.'
	}, {
		'name': 'Holy symbol, wooden',
		'qty': null,
		'cost': '1',
		'denomination': 'gp',
		'pounds': '·',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Holy symbol, silver',
		'qty': null,
		'cost': '25',
		'denomination': 'gp',
		'pounds': '1',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Holy water',
		'qty': 'flask',
		'cost': '25',
		'denomination': 'gp',
		'pounds': '1',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Ink',
		'qty': '1 oz',
		'cost': '8',
		'denomination': 'gp',
		'pounds': '·',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': 'This is black ink. One can buy ink in other colors, but it costs twice as much.'
	}, {
		'name': 'Quill pen',
		'qty': null,
		'cost': '1',
		'denomination': 'sp',
		'pounds': '·',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Ladder',
		'qty': '10\'',
		'cost': '5',
		'denomination': 'cp',
		'pounds': '20',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Lantern',
		'qty': null,
		'cost': '9',
		'denomination': 'gp',
		'pounds': '3',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': 'Lanterns can be closed to hide the light. They burn one oil flask for each four hours, or 24 turns, and have an effective light radius of 30 feet.'
	}, {
		'name': 'Lock',
		'qty': null,
		'cost': '20',
		'denomination': 'gp',
		'pounds': '1',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': 'This is a common iron lock with a key.'
	}, {
		'name': 'Manacles',
		'qty': null,
		'cost': '15',
		'denomination': 'gp',
		'pounds': '2',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': 'These are used to bind hands or feet.'
	}, {
		'name': 'Mirror, large metal',
		'qty': null,
		'cost': '15',
		'denomination': 'gp',
		'pounds': '8',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Mirror, small silver',
		'qty': null,
		'cost': '25',
		'denomination': 'gp',
		'pounds': '0.5',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Mirror, small steel',
		'qty': null,
		'cost': '10',
		'denomination': 'gp',
		'pounds': '0.5',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Oil',
		'qty': '1 pint',
		'cost': '1',
		'denomination': 'sp',
		'pounds': '1',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Paper',
		'qty': '1 sheet',
		'cost': '4',
		'denomination': 'sp',
		'pounds': null,
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Parchment',
		'qty': '1 sheet',
		'cost': '2',
		'denomination': 'sp',
		'pounds': '·',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Pick, miner\'s',
		'qty': null,
		'cost': '3',
		'denomination': 'gp',
		'pounds': '10',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Pole',
		'qty': '10\' wooden',
		'cost': '2',
		'denomination': 'sp',
		'pounds': '8',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Pouch, large belt',
		'qty': null,
		'cost': '18',
		'denomination': 'sp',
		'pounds': '0.5',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Pouch, small belt',
		'qty': null,
		'cost': '1',
		'denomination': 'ep',
		'pounds': '·',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Rope, hemp',
		'qty': '50\'',
		'cost': '1',
		'denomination': 'gp',
		'pounds': '10',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': 'This strong rope can hold the weight of approximately three human-sized beings.'
	}, {
		'name': 'Rope, silk',
		'qty': '50\'',
		'cost': '10',
		'denomination': 'gp',
		'pounds': '5',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': 'This rope is stronger than hemp, and can hold the weight of five human-sized beings.'
	}, {
		'name': 'Sack, Large',
		'qty': null,
		'cost': '2',
		'denomination': 'sp',
		'pounds': '0.5',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': 'This sack can contain 60 pounds.'
	}, {
		'name': 'Sack, Small',
		'qty': null,
		'cost': '1',
		'denomination': 'sp',
		'pounds': '0.5',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': 'This sack can contain 20 pounds.'
	}, {
		'name': 'Saddle',
		'qty': null,
		'cost': '25',
		'denomination': 'gp',
		'pounds': '25',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Saddle Bag',
		'qty': null,
		'cost': '1',
		'denomination': 'sp',
		'pounds': '0.5',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': 'This bag can contain 30 pounds.'
	}, {
		'name': 'Spade or shovel',
		'qty': null,
		'cost': '2',
		'denomination': 'gp',
		'pounds': '8',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Spellbook (Blank)',
		'qty': null,
		'cost': '15',
		'denomination': 'gp',
		'pounds': '3',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': 'A spellbook has 100 pages of parchment, and each spell takes up one page per spell level (one page each for 1st level spells). These books can be used by an elf or magic- user for recording spells.'
	}, {
		'name': 'Spikes, iron',
		'qty': '12',
		'cost': '1',
		'denomination': 'gp',
		'pounds': '8',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Spyglass',
		'qty': null,
		'cost': '1000',
		'denomination': 'gp',
		'pounds': '1',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Stakes, wooden',
		'qty': '3',
		'cost': '5',
		'denomination': 'cp',
		'pounds': '1',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Thieves Tools',
		'qty': null,
		'cost': '30',
		'denomination': 'gp',
		'pounds': '1',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': 'This kit contains all of the tools a thief needs to pick locks.'
	}, {
		'name': 'Torches',
		'qty': '8',
		'cost': '3',
		'denomination': 'sp',
		'pounds': '8',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': 'A torch burns for 1 hour, clearly illuminating a 30-foot radius. If a torch is used in combat, it deals 1d4 damage.'
	}, {
		'name': 'Vial',
		'qty': null,
		'cost': '1',
		'denomination': 'gp',
		'pounds': '0.1',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': 'A vial holds 1 ounce of liquid. The stoppered container usually is no more than 1 inch wide and 3 inches high.'
	}, {
		'name': 'Waterskin/Wineskin',
		'qty': null,
		'cost': '1',
		'denomination': 'gp',
		'pounds': '4',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': 'This container, made of hide, will hold 2 pints (1 quart) of fluid.'
	}, {
		'name': 'Wine',
		'qty': '2 pints',
		'cost': '1',
		'denomination': 'gp',
		'pounds': '0.5',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Gear',
		'notes': ''
	}, {
		'name': 'Belladonna',
		'qty': null,
		'cost': '4',
		'denomination': 'sp',
		'pounds': null,
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Provisions',
		'notes': 'This herb is used to relieve aches and pains, reduce inflammation, relieve coughs or used as an anesthetic.'
	}, {
		'name': 'Feverfew',
		'qty': null,
		'cost': '5',
		'denomination': 'cp',
		'pounds': null,
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Provisions',
		'notes': 'This herb can be used to induce sleep, reduce fevers, and alleviate headaches or arthritis.'
	}, {
		'name': 'Garlic',
		'qty': null,
		'cost': '4',
		'denomination': 'cp',
		'pounds': null,
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Provisions',
		'notes': 'This herb is effective at repelling vampires. It may be used to treat bacterial infection, viral infection, fungal infection, and intestinal parasites'
	}, {
		'name': 'Hollyhock',
		'qty': null,
		'cost': '4',
		'denomination': 'cp',
		'pounds': null,
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Provisions',
		'notes': 'This herb can be used to treat burns, relieve itching, and reduce inflammation. It may also be used to treat diarrhea and intestinal parasites.'
	}, {
		'name': 'Rue',
		'qty': null,
		'cost': '3',
		'denomination': 'sp',
		'pounds': null,
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Provisions',
		'notes': 'This herb may ward against demons or their worshippers. It may also be used in a pain relieving poultice, or internally as a relaxant or to treat cough or diarrhea.'
	}, {
		'name': 'Sage',
		'qty': null,
		'cost': '4',
		'denomination': 'cp',
		'pounds': null,
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Provisions',
		'notes': 'This herb has diverse uses, including treatment of bacterial or fungal infection, treatment of spasms, or to aid powers of concentration. This herb may also be a component of cleansing folk rituals.'
	}, {
		'name': 'Spiderwort',
		'qty': null,
		'cost': '2',
		'denomination': 'sp',
		'pounds': null,
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Provisions',
		'notes': 'This herb may be used to treat poisonous stings or bites. It is also used as a laxative or to treat kidney, stomach, or other digestive problems.'
	}, {
		'name': 'Wolfsbane',
		'qty': null,
		'cost': '8',
		'denomination': 'sp',
		'pounds': null,
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Provisions',
		'notes': 'This herb can be used to repel lycanthropes. It may be used to treat inflammation and wounds.'
	}, {
		'name': 'Yarrow',
		'qty': null,
		'cost': '4',
		'denomination': 'cp',
		'pounds': null,
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Provisions',
		'notes': 'This herb may be used as an antiseptic and antibiotic or to treat illness. It can be used on wounds to help stop bleeding.'
	}, {
		'name': 'Ale',
		'qty': 'Gallon',
		'cost': '1',
		'denomination': 'ep',
		'pounds': '10',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Provisions',
		'notes': ''
	}, {
		'name': 'Ale',
		'qty': 'Pint',
		'cost': '1',
		'denomination': 'sp',
		'pounds': '1',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Provisions',
		'notes': ''
	}, {
		'name': 'Beer',
		'qty': 'Gallon',
		'cost': '4',
		'denomination': 'sp',
		'pounds': '10',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Provisions',
		'notes': ''
	}, {
		'name': 'Beer',
		'qty': 'Pint',
		'cost': '5',
		'denomination': 'cp',
		'pounds': 1,
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Provisions',
		'notes': ''
	}, {
		'name': 'Bread',
		'qty': 'Loaf',
		'cost': '2',
		'denomination': 'cp',
		'pounds': '0.5',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Provisions',
		'notes': ''
	}, {
		'name': 'Cheese',
		'qty': 'Brick',
		'cost': '6',
		'denomination': 'sp',
		'pounds': '5',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Provisions',
		'notes': ''
	}, {
		'name': 'Eggs, pickled',
		'qty': 'Cask',
		'cost': '12',
		'denomination': 'sp',
		'pounds': '20',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Provisions',
		'notes': ''
	}, {
		'name': 'Fish, dry salted',
		'qty': 'Barrel',
		'cost': '40',
		'denomination': 'gp',
		'pounds': '60',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Provisions',
		'notes': ''
	}, {
		'name': 'Inn, Good',
		'qty': null,
		'cost': '5',
		'denomination': 'sp',
		'pounds': null,
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Provisions',
		'notes': ''
	}, {
		'name': 'Inn, Common',
		'qty': null,
		'cost': '3',
		'denomination': 'sp',
		'pounds': null,
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Provisions',
		'notes': ''
	}, {
		'name': 'Inn, Poor',
		'qty': null,
		'cost': '1',
		'denomination': 'sp',
		'pounds': null,
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Provisions',
		'notes': ''
	}, {
		'name': 'Meal, good',
		'qty': null,
		'cost': '5',
		'denomination': 'sp',
		'pounds': null,
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Provisions',
		'notes': ''
	}, {
		'name': 'Meal, common',
		'qty': null,
		'cost': '3',
		'denomination': 'sp',
		'pounds': null,
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Provisions',
		'notes': ''
	}, {
		'name': 'Meal, poor',
		'qty': null,
		'cost': '1',
		'denomination': 'sp',
		'pounds': null,
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Provisions',
		'notes': ''
	}, {
		'name': 'Mead, pint',
		'qty': null,
		'cost': '5',
		'denomination': 'sp',
		'pounds': null,
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Provisions',
		'notes': ''
	}, {
		'name': 'Mead, bottle',
		'qty': null,
		'cost': '12',
		'denomination': 'sp',
		'pounds': '1.5',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Provisions',
		'notes': ''
	}, {
		'name': 'Rations, standard',
		'qty': 'per day',
		'cost': '2',
		'denomination': 'sp',
		'pounds': '1',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Provisions',
		'notes': 'This food is fresh and will not keep for more than a few days. The cost for this food would reflect fresh food fixed for a militia, or the most basic food at an inn.'
	}, {
		'name': 'Rations, trail',
		'qty': 'per day',
		'cost': '5',
		'denomination': 'sp',
		'pounds': '1',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Provisions',
		'notes': 'This food is dried and preserved to be carried on long voyages when securing other food may be uncertain.'
	}, {
		'name': 'Wine, common',
		'qty': 'Pint',
		'cost': '5',
		'denomination': 'sp',
		'pounds': null,
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Provisions',
		'notes': ''
	}, {
		'name': 'Wine, common',
		'qty': 'Bottle',
		'cost': '12',
		'denomination': 'sp',
		'pounds': '1.5',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Provisions',
		'notes': ''
	}, {
		'name': 'Wine, good',
		'qty': 'Pint',
		'cost': '10',
		'denomination': 'sp',
		'pounds': null,
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Provisions',
		'notes': ''
	}, {
		'name': 'Wine, good',
		'qty': 'Bottle',
		'cost': '22',
		'denomination': 'sp',
		'pounds': '1.5',
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Provisions',
		'notes': ''
	}, {
		'name': 'Boat, River',
		'qty': null,
		'cost': '4000',
		'denomination': 'gp',
		'pounds': null,
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Transport',
		'notes': 'A riverboat can carry 3,000 pounds. It is 10 feet wide and between 20-30 feet long, and has a „draft,‰ or surface depth, of between 2-3 feet when in the water. Riverboats are rowed, or poles are used to push it along. The cost of the boat increases by 1,000 gp if it has a roof.'
	}, {
		'name': 'Boat, Sailing',
		'qty': null,
		'cost': '2000',
		'denomination': 'gp',
		'pounds': null,
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Transport',
		'notes': 'This boat may be 10 feet longer than a riverboat, but is otherwise similar in dimensions and travels by sail. A sailing boat can carry the weight of 2,000 pounds.'
	}, {
		'name': 'Canoe',
		'qty': null,
		'cost': '55',
		'denomination': 'gp',
		'pounds': null,
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Transport',
		'notes': 'A canoe is a small boat that weighs 50 pounds. It can carry weight up to 600 pounds and is about 15 feet long.'
	}, {
		'name': 'Galley, Large',
		'qty': null,
		'cost': '32000',
		'denomination': 'gp',
		'pounds': null,
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Transport',
		'notes': 'This is a large ship, capable of holding a weight of 4,000 pounds. These ships are 15-20 feet wide, 120-150 feet long, and have a draft of 3 feet. This ship is manned by 180 rowers. Further, in addition to the captain, there is generally a crew of 70. These ships are sometimes equipped with catapults and a ram.'
	}, {
		'name': 'Galley, Small',
		'qty': null,
		'cost': '12000',
		'denomination': 'gp',
		'pounds': null,
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Transport',
		'notes': 'This ship is capable of holding a weight of 4,000 pounds. These ships are 10-15 feet wide, 60-100 feet long, and have a draft of 2-3 feet. This ship is manned by 60 rowers. Further, in addition to the captain, there is generally a crew of 40. These ships are sometimes equipped with catapults and a ram.'
	}, {
		'name': 'Galley, War',
		'qty': null,
		'cost': '65000',
		'denomination': 'gp',
		'pounds': null,
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Transport',
		'notes': 'This is a large ship that is generally a fleetÊs flagship, capable of holding a weight of 6,000 pounds. These ships are 20-30 feet wide, 120-150 feet long, and have a draft of 4-6 feet. This ship is manned by 300 rowers. Further, in addition to the captain, there is generally a crew of 100. These ships are equipped with 3 catapults and a ram.'
	}, {
		'name': 'Lifeboat',
		'qty': null,
		'cost': '800',
		'denomination': 'gp',
		'pounds': null,
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Transport',
		'notes': 'This ship is capable of holding a weight of 1,500 pounds. These ships are 4-5 feet wide, 20 feet long, and have a draft of 1-2 feet. They are equipped with rations to feed 10 human-sized beings for 1 week. The mast folds down for storage of the lifeboat on larger galleys, where there are typically 2 to 3 lifeboats. There are 1 or 2 lifeboats on smaller galleys. Lifeboats weigh 500 pounds and will take up this much weight, each, on a galley.'
	}, {
		'name': 'Longship',
		'qty': null,
		'cost': '17000',
		'denomination': 'gp',
		'pounds': null,
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Transport',
		'notes': 'This is a narrow ship capable of holding a weight of 4,000 pounds. These ships are 10-15 feet wide, 60-80 feet long, and have a draft of 2-3 feet. This ship requires 60 rowers, but is also capable of being sailed. In addition to the captain, there is generally a crew of 75 sailors, of which 60 may row when the wind is low.'
	}, {
		'name': 'Raft',
		'qty': 'Per sf',
		'cost': '1',
		'denomination': 'gp',
		'pounds': null,
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Transport',
		'notes': 'Rafts can be professional or makeshift water vessels that can be no larger than 40x40 feet. For every 10x10 area of well- built rafts, the raft can hold a weight of 1,000 pounds. If the raft is makeshift, it will only hold a weight of 500 pounds for each 10x10 foot area. A makeshift raft can be built in 1 to 3 days for each 10x10 foot raft portion.'
	}, {
		'name': 'Sailing Ship, Large',
		'qty': null,
		'cost': '22000',
		'denomination': 'gp',
		'pounds': null,
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Transport',
		'notes': 'This large, seaworthy ship is 100 to 150 feet long, 25 to 30 feet wide, a draft of 10 to 12 feet, and has a crew of 70. It can carry 30,000 pounds of cargo. It has square sails on its three masts and is sometimes equipped with two catapults.'
	}, {
		'name': 'Sailing Ship, Small',
		'qty': null,
		'cost': '7000',
		'denomination': 'gp',
		'pounds': null,
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Transport',
		'notes': 'This ship is much like the larger version, but is 60 to 80 feet long, 20 to 30 feet wide, a draft of 5 to 8 feet, and has a crew of 12. It can carry 10,000 pounds of cargo, and has one mast.'
	}, {
		'name': 'Sailing Ship, Transport',
		'qty': null,
		'cost': '30000',
		'denomination': 'gp',
		'pounds': null,
		'oneHandDamage': null,
		'twoHandDamage': null,
		'armorClass': null,
		'type': 'Transport',
		'notes': 'This large ship has similar dimensions and characteristics to a large sailing ship. However, it is specially designed to carry troops, mounts, and equipment of war as its cargo.'
	}
]
